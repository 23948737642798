import React from 'react'
// import logo from './IMG_20230321_200359_968.png'
import logo from './logoImg.png'
import './index.module.css'

const Logo = () => {
  return (
    <img src={logo}/>
  )
}

export default Logo